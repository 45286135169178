import React, { useRef, useContext, useState } from 'react';
import ChapterVideo from './ChapterVideo';
import { UserContext } from "../App";
import GetAppIcon from '@mui/icons-material/GetApp';
import SchoolIcon from '@mui/icons-material/School';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import {
  Accordion,
  Card,
  ListGroup,
  ListGroupItem
} from 'react-bootstrap';

import { Container } from 'reactstrap';

const NewSelfCourse = (props) => {

  const location = useLocation();
  const { myClasses, user } = useContext(UserContext);
  const { grade, semester, mode } = props;

  console.log(myClasses);

  const courseObj = (myClasses.length > 0) ? myClasses.find(x => x.isRotary == true && x.grade == grade && x.semester == semester) : null;
  console.log(courseObj);
  if (courseObj == null) return <p>課程資料載入中</p>;
  else {
    var course = JSON.parse(courseObj.courseData);
    var course_id = course.grade + '-' + course.semester;
    console.log(course);
  }

  if( mode === 'Misc' ){
    return (     
    <Container>
      <Accordion defaultActiveKey={course_id}>
        <Card key={course_id}>
          <Card.Header as="h2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>{course.title} - 培訓紀錄</span>
          </Card.Header>
          <Card.Body>
            {course.miscs.map((miscVideo, idx_chapter) =>
              console.log(miscVideo) ||
              <div key={idx_chapter} id={`SEC-${course.id}-${idx_chapter}`}>
                <h4 className="h4" style={{ paddingTop: .4 + 'em', fontSize: '12pt' }}>{miscVideo.title}....</h4>
                <ListGroup>
                  <ListGroupItem key={+ '_' + idx_chapter + '_video'} style={{ fontSize: '12pt' }}>
                    <ChapterVideo idx={`Y${course.grade}-S${course.semester}`} video={miscVideo} />
                  </ListGroupItem>
                </ListGroup>
              </div>
            )}
          </Card.Body>
        </Card>
      </Accordion>
    </Container> );
  }

  return (
    <Container>
      <Accordion defaultActiveKey={course_id}>
        <Card key={course_id}>
          <Card.Header as="h2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>{course.title}</span>
          </Card.Header>
          <Card.Body>
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', paddingRight: '10px' }}>
            {course_id === "1-1" && (
              <>
                <Link to={`${location.pathname}/Misc`} target="_blank" style={{ fontSize: '12pt' }}>
                  培訓紀錄 <SchoolIcon style={{ marginLeft: '5px' }} />
                </Link>
                <Link to="/files/扶輪芯福影音EQ課-教師操作手冊0924.pdf" target="_blank" download style={{ fontSize: '12pt' }}>
                  教師手冊 <GetAppIcon style={{ marginLeft: '5px' }} />
                </Link>
              </>
            )}
            <Link to={{pathname: `/Class/24`}} target="_blank" style={{ fontSize: '12pt' }}>
              理論課 <SchoolIcon style={{ marginLeft: '5px' }} />
            </Link>
          </div>
            {course.chapters.map((chapter, idx_chapter) =>
              <div key={idx_chapter} id={`SEC-${course.id}-${idx_chapter}`}>
                <h4 className="h4" style={{ paddingTop: .4 + 'em', fontSize: '12pt' }}>{chapter.title}</h4>
                <ListGroup>
                  {chapter.videos.map((v, videoIndex) =>
                    <ListGroupItem key={+ '_' + idx_chapter + '_' + videoIndex} style={{ fontSize: '12pt' }}>
                      <ChapterVideo idx={`Y${course.grade}-S${course.semester}`} video={v} />
                    </ListGroupItem>
                  )}
                </ListGroup>
              </div>
            )}
          </Card.Body>
        </Card>
      </Accordion>
    </Container>
  );
}


export { NewSelfCourse };